import React, { useState, useCallback } from "react"
import {
  LegacyCard,
  LegacyStack,
  Button,
  Modal,
  ChoiceList,
  Thumbnail,
} from "@shopify/polaris"
import styled from "styled-components"
import update from "immutability-helper"
const FeaturedImageWrapper = styled.div`
  .featued-image-title {
    display: block;
    padding-bottom: 5px;
  }
  .Polaris-Stack__Item:last-of-type {
    display: flex;
    align-items: center;
  }
`
function FeaturedImage(props) {
  const { state, setState, product, offerIndex, index } = props
  const [active, setActive] = useState(false)
  const [selectedImage, setSelectedImage] = useState([])
  const handleImageChange = useCallback((value) => setSelectedImage(value), [])
  const handleModalChange = useCallback(() => setActive(!active), [active])
  const updateFeaturedImage = () => {
    if (selectedImage.length > 0) {
      setState(
        update(state, {
          product: {
            [offerIndex]: {
              products: {
                [index]: { featuredImage: { $set: selectedImage[0] } },
              },
            },
          },
        })
      )
    }
    handleModalChange()
  }
  const activator = <Button onClick={handleModalChange}>Change image</Button>
  // the product could be state, state.product or state.products(new multiple product feature), depending on where the component is used

  return (
    <FeaturedImageWrapper>
      <span className="featued-image-title">Featured image</span>
      <LegacyCard>
        <LegacyCard.Section>
          <LegacyStack>
            <LegacyStack.Item fill>
              <img
                style={{
                  width: 60,
                  height: 60,
                  objectFit: "contain",
                  objectPosition: "center",
                  border: "1px solid #AEB4B9",
                  borderRadius: "5px",
                }}
                src={
                  product?.featuredImage && product?.product?.id
                    ? product.featuredImage
                    : "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/200.png?alt=media&token=2cf839eb-05e5-49b1-a591-4fc02e78e94b"
                }
              />
            </LegacyStack.Item>
            <LegacyStack.Item style={{ display: "flex" }} className="test">
              {product.product && (
                <Modal
                  activator={activator}
                  open={active}
                  onClose={handleModalChange}
                  title="Images"
                  primaryAction={{
                    content: "Add image",
                    onAction: () => updateFeaturedImage(),
                  }}
                  secondaryActions={[
                    {
                      content: "Cancel",
                      onAction: handleModalChange,
                    },
                  ]}
                >
                  <Modal.Section>
                    <ChoiceList
                      choices={
                        product.product.images
                          ? product.product.images.map((image) => {
                              return {
                                label: <Thumbnail source={image.originalSrc} />,
                                value: image.originalSrc,
                              }
                            })
                          : {
                              label: (
                                <Thumbnail source={product.product.image} />
                              ),
                              value: product.product.image,
                            }
                      }
                      selected={selectedImage}
                      onChange={handleImageChange}
                    />
                  </Modal.Section>
                </Modal>
              )}
            </LegacyStack.Item>
          </LegacyStack>
        </LegacyCard.Section>
      </LegacyCard>
    </FeaturedImageWrapper>
  );
}

export default FeaturedImage
